/* Privacy Policy Container */
.zq-privacy-policy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

  /* Header Styles */
.zq-pp-header {
    background-color: #761034;
    color: #fff;
    text-align: center;
    padding: 5rem 0;
    width: 90%;
    line-height: normal;
}

/* Main Content Styles */
.zq-pp-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 100%;
    gap: 2rem;
    margin: auto;
    padding: 2rem 0;
}
.zq-pp-main-intro,
.zq-pp-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    gap: .8rem;
    height: 100%;
    margin: auto;
    padding: 1rem 0;
    font-size: 1.1rem;
    line-height: normal;
}

.zq-pp-content h2{
    color:#761034;
}
.zq-pp-details-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    gap: .8rem;
    height: 100%;
}
.zq-pp-details-item li{
  font-size: clamp(1rem, 1.5vw, 1.2rem);
}

/* Footer Styles */
footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  gap: .8rem;
  height: 100%;
  padding: 1rem 3rem;
  background-color: #761034;
  color: whitesmoke;
}

@media screen and (max-width: 672px)    {


    footer{
        width: 100%;
    }

    .zq-pp-header {
        width: 100%;
    }


}


