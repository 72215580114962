/* abt-body.css */

.conferenceVenue {
    display: flex;
    flex-direction: row;
    margin-top: 10%;
    background-color: white;
  }
  .backdrop {
    width: 50%;
    height: 100%;
    margin-left: 5rem;
    display: flex;
    flex-direction: column;
  }
  .backdrop img {
    max-height: 100%;
    width: 100%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 2%;
    /* opacity: 0.7; */
  }
  .space {
    margin: 2%;
  }
  .textRight {
    flex-grow: 1;
    margin-right: 3%;
    margin-left: 3%;
    margin-top: 5%;
    width: 50%;
    height: 100%;
    text-align: center;
  }
  .titleConference {
    text-align: center;
    color: #7C1034;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 5%;
  }
  .textTitleLarge {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 5%;
    line-height: normal;
  }
  .textContent {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 5%;
    line-height: normal;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
  }
  .textAddress {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 5%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .locationBtn {
    width: 40%;
    outline: none;
    box-shadow: none;
    background-color: rgb(72, 44, 44);
    color: rgb(228, 220, 220);
    padding: 0.75rem;
    border: 0.1rem solid rgb(228, 220, 220);
    border-radius: 0.5rem;
    cursor: pointer;
    margin-top: 2%;
    font-size: 1rem;
  }
  .locationBtn:hover {
    background-color: black;
    color: #7C1034;
    border: 0.1rem solid #7C1034;
  }
  /* Mobile Phones */
  @media screen and (max-width: 672px) {
    .conferenceVenue {
      flex-direction: column;
      align-items: center;
    }
    .backdrop {
      width: 100%;
      margin-bottom: 2%;
      margin-left: 0rem;
    }
    .textRight {
      width: 100%;
      margin-top: 5%;
    }
    .titleConference {
      font-size: 1rem;
      margin-bottom: 5%;
    }
    .textTitleLarge {
      font-size: 1.3rem;
      margin-bottom: 5%;
    }
    .textContent {
      font-size: 0.8rem;
      margin-bottom: 5%;
    }
    .textAddress {
      font-size: 0.7rem;
      margin-bottom: 5%;
    }
    .locationBtn {
      width: 50%;
      padding: 1rem;
      border: 0.01rem solid rgb(228, 220, 220);
      border-radius: 0.3rem;
      margin-top: 1%;
      font-size: 0.8rem;
    }
  }
  /* Tablets and Small Laptops */
  @media screen and (min-width: 672px) and (max-width: 990px) {
    .textRight {
      flex-grow: 1;
      margin-right: 5%;
      margin-left: 5%;
      margin-top: 15%;
      width: 50%;
    }
  
    .titleConference {
      font-size: 0.75rem;
      margin-bottom: 5%;
    }
  
    .textTitleLarge {
      font-size: 1rem;
      margin-bottom: 5%;
    }
  
    .textContent {
      font-size: 0.75rem;
      margin-bottom: 5%;
    }
  
    .textAddress {
      font-size: 0.75rem;
      margin-bottom: 5%;
    }
  
    .locationBtn {
      width: 35%;
      padding: 0.5rem;
      border: 0.01rem solid rgb(228, 220, 220);
      border-radius: 0.3rem;
      margin-top: 1%;
      font-size: 0.75rem;
    }
    .backdrop{
      margin-left: 0rem;
    }
  }
  
  /* Desktops and Large Laptops */
  @media screen and (min-width: 990px) and (max-width: 1296px) {
    .textRight {
      flex-grow: 1;
      margin-right: 5%;
      margin-left: 5%;
      margin-top: 2%;
      width: 50%;
    }
  
  }
  