.accordion    {
    margin-top: 2%;
    margin-bottom: 10%;
}

.title    {
    font-size: 1.5rem;
    font-weight: 800;
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
    line-height: normal;
}
.faq-banner img{
    width: 100%;
    height: auto;
    padding: 0px 5rem 0px 5rem;

    /* height: 80vh; */
}
.faq-footer h3{
    text-align: center;
    line-height: normal;
    margin-top: 2%;
}


/* Mobile Phones */
@media screen and (max-width: 672px)    {
    .title    {
        font-size: 1.5rem;
    }
}