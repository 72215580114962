.blogs-title{

    text-align: center;
    font-weight: bolder;
    
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 200px;
  background: #F1F1F1;
  margin: 2rem 0;
  width: 100%;
  margin-left: 43%;


  padding: 0.2rem;
  border: 1px solid #ccc;
  border-radius: 10px;
}
  
 
  

  
  .search-container button:hover {
    background: #ccc;
  }


  .blogsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    gap: 5%;
    width: 70%;
    margin-top: 5%;
    margin-left: 10%;
}

.blogMember {
    flex-basis: calc(45% - 20px);
    transition: transform 0.5s, box-shadow 0.5s;



}
.blogMemberName {
  color: #6b3648;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 3%;
}

.blogMemberPosition {
  font-size: 1.3rem;
  font-weight: 300;
  text-align: center;
  margin-top: 3%;
  margin-bottom: 15%;
}


.blogMember img {
    width: 100%;
    height: auto;
    margin-bottom: 10%;
    /* border-radius: 10%; */

}

.teamMemberName {
    color: #6b3648;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
}

.teamMemberPosition {
    font-size: 1.3rem;
    font-weight: 300;
    text-align: center;
    margin-top: 3%;
    margin-bottom: 15%;
}

.bloginfoItem    {
  margin-top: 5%;
  font-size: clamp(1rem, 1.3vw, 1.5rem);
  line-height: normal;
}

.blog-text{
  padding: 0% 20% 5% 20%;
  font-size: 1.3rem;
  line-height: normal;
  font-weight: 100;
  color: rgb(38, 57, 38);
  font-style: oblique;
  margin-top: 2%;
}

.blogs-banner img{
  width: 100%;
  height: auto;
  height: 90vh;
  padding: 0% 20% 5% 20%;
  margin-top: 5%;

}

.blog-user{
  display: flex;
}

.blog-usercontent{
  margin-left: 0.5rem;
}

.blog-usercontent p{
  color: grey;
}
@media screen and (max-width: 672px)    {


  .blogs-banner img{
    width: 100%;
    height: auto;
    padding: 0% 20% 5% 20%;
  
  }

  .blogMemberName {
    font-size: 0.6rem;
  }
.blog-usercontent{
  font-size: 0.4rem;

}

.search-container {
  margin-left: 20%;
}


}