.form-container {
    width: 100%;
    margin: 0 auto;
    /* background-color: azure; */
    /* padding: 50px; */
    margin-bottom: 5%;
    /* flex-basis: calc(45% - 20px);
    transition: transform 0.5s, box-shadow 0.5s; */
  }
  .actual-form{
    width: 50%;
    height: auto;
  }
  .form-pic{
    width: 50%;
    height: auto;
  }
  /* .form-container:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);

} */

  .form-container label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .form-container input[type="text"],
  .form-container input[type="email"] {
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    background-color: #fff;
  }
  
  .form-container button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.8rem 1rem;
    cursor: pointer;
    margin-top: 6%;
    width: 10rem;
  }
  .form-group{
border-bottom-style: solid; 
border-bottom-color: #c3bcbc;
width: 80%;
 }
  
  .form-container button:hover {
    background-color: #0056b3;
  }
  
  .form-content{
    padding: 5% 5% 5% 5%;
    line-height: normal;
    color: #761064;
  }

  @media screen and (max-width: 740px)    {

    .form-content{
        padding: 5% 10% 5% 10%;


      }
      .form-container{
        flex-direction: column;
        width: 100%;
      }
      .form-pic{
        width: 100%;
        height: auto;

      }
      .actual-form{
        width: 100%;
        height: auto;
        padding: 5%;


      }

  }
