.body{
    background-color: white;
}
.navigation    {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: white;
}

.logo    {
    flex: 1;
    cursor: pointer;
}

.logo img    {
    height: auto;
    width: 100%;

    margin-left: 5rem;
}

.topList    {
    display: flex;
    flex-direction: row;
    list-style: none;
}

.topListItemLink    {
    cursor: pointer;
    text-decoration: none;
    color: black;
}
.topListItemLink:hover    {
    color: #4b1623;
    font-weight: 600;
}

.navigation-menu    {
    margin-right: 5%;
}

.navigation-menu ul    {
    display: flex;
    padding: 0;
    width: 100%;
}

.navigation-menu li    {
    list-style-type: none;
}

.navigation-menu li a    {
    text-decoration: none;
    display: block;
}

.hamburger    {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #761034;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
    /* color: rgb(228, 220, 220); */
}

.hamburger:hover    {
    color: #fcf7f8;
}

/* Mobile Phones */
@media screen and (max-width: 672px)    {
    .logo img    {
        width: 30px;
        height: 30px;
        margin-left: 2rem;
    }

    .hamburger    {
        display: block;
    }

    .navigation-menu    {
        background-color: black;
    }

    .navigation-menu ul    {
        position: absolute;
        top: 60px;
        right: 0;
        flex-direction: column;
        width: 60%;
        height: calc(100vh - 77px);
        background-color: rgba(40,40,47);
        border-top: 1px solid;
        display: none;
        margin-top: 10%;
      }

      .navigation-menu.expanded ul    {
        display: block;
        color: black;
        height: auto;
      }

      .navigation-menu li    {
        text-align: center;
        width: 100%;
      }
      .navigation-menu li a    {
        padding: 1.5rem 0;
      }
      .navigation-menu li:hover    {
        background-color: black;
      }
      .topListItemLink{
        color: whitesmoke;
      }
}

/* Tablets and Small Laptops */
@media screen and (min-width: 672px) and (max-width: 990px)    {
    .logo img    {
        width: 30px;
    height: 30px;
    }

    .navigation-menu li    {
        margin: 0.7rem;
    }

    .topListItemLink    {
        font-size: 1.5rem;
    }
}

/* Desktops and Large Laptops */
@media screen and (min-width: 990px) and (max-width: 1296px)    {
    .logo img    {
        width: 180px;
        height: 80px;
    }

    .navigation-menu li    {
        margin: 0 0.5rem;
    }

    .topListItemLink    {
        font-size: 1.2rem;
    }
}

/* Large Desktops and High-resolution Displays */
@media screen and (min-width: 1296px)    {
    .logo img    {
        width: 50px;
        height: 50px;
    }

    .navigation-menu li    {
        margin: 0 0.7rem;
    }

    .topListItemLink   {
        font-size: 1.2rem;
    }
}


