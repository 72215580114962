.adminBody    {
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminImage    {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.adminImage img    {
    width: 100%;
    height: auto;
}
.adminLoginForm    {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;;
    flex: 1;
}

.adminText    {
    display: flex;
    text-align: center;
}

.adminTitle    {
    font-size: 2rem;
    font-weight: 800;
    margin-top: 5%;
    margin-bottom: 15%;
}
.email,
.password,
.recovery    {
    width: 80%;
    height: auto;
    margin-bottom: 5%;
    font-size: 1.5rem;
}
.emailText,
.passwordText   {
    font-size: 1.5rem;
    font-weight: 600;
    color:#7C1034  ;
}
.emailInput,
.passwordInput    {
    width: 100%;
    height: auto;
    padding: 2%;
    border: 0.1rem solid rgb(228, 220, 220);
    border-radius: 1%;
    font-size: 0.75rem;
    margin-top: 5%;
}

.rememberMeInput    {
    margin-right: 2%;
    margin-left: 5%;
}

.rememberMeText    {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
}
.forgetPassword    {
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    margin-left: 30%;
}

.forgetPassword:hover    {
    color:#7C1034  ;
}

.adminLogin    {
    width: 30%;
}

.loginButton    {
    width: 100%;
    height: auto;
    padding: 0.5rem;
    border: 0.1rem solid rgb(228, 220, 220);
    border-radius: 0.5rem;
    font-size: 1rem;
    margin-top: 10%;
    color: white;
    background-color: black;
    font-weight: 400;
    cursor: pointer;
}

.loginButton:hover     {
    color:#7C1034  ;
    border-color:#7C1034  ;
}

/* Mobile Phones */
@media screen and (max-width: 672px)    {
    .adminBody    {
        flex-direction: column;
        align-items: center;
    }

    .adminImage    {
        flex: none;
        order: 1;
        width: 100%;
    }

    .adminImage img     {
        width: 600px;
        height: 150px;
        object-fit: cover;
    }

    .adminLoginForm    {
        flex: none;
        order: 2;
        margin-left: 0;
        margin-right: 0;
        margin-top: 15%;
        margin-bottom: 15%;
    }

    .adminTitle    {
        font-size: 2rem;
        margin-bottom: 20%;
    }
    .email,
    .password,
    .recovery    {
        width: 100%;
        height: auto;
        margin-bottom: 5%;
    }
    .emailText,
    .passwordText   {
        font-size: 1rem;
    }
    .emailInput,
    .passwordInput    {
        width: 100%;
        padding: 3%;
        font-size: 0.75em;
    }

    input[type=checkbox]    {
        width: 0.5rem;
        height: 0.5rem;
    }

    .rememberMeInput    {
        margin-right: 2%;
        margin-left: 5%;
    }
    .rememberMeText    {
        font-size: 0.5rem;
        text-align: center;
    }
    .forgetPassword    {
        font-size: 0.5rem;
        margin-left: 30%;
    }

    .adminLogin    {
        width: 40%;
    }
    .loginButton    {
        width: 100%;
        padding: 0.25rem;
        font-size: 0.75rem;
        margin-top: 30%;
        border-radius: 0.25rem;
    }
}

/* Tablets and Small Laptops */
@media screen and (min-width: 672px) and (max-width: 990px)    {
    .adminTitle    {
        font-size: 1.5rem;
        margin-bottom: 20%;
    }
    .emailText,
    .passwordText   {
        font-size: 0.75rem;
    }
    .emailInput,
    .passwordInput    {
        width: 100%;
        padding: 3%;
        font-size: 0.5em;
    }

    input[type=checkbox]    {
        width: 0.5rem;
        height: 0.5rem;
    }

    .rememberMeInput    {
        margin-right: 2%;
        margin-left: 5%;
    }
    .rememberMeText    {
        font-size: 0.5rem;
        text-align: center;
    }
    .forgetPassword    {
        font-size: 0.5rem;
        margin-left: 17%;
    }

    .adminLogin    {
        width: 30%;
    }
    .loginButton    {
        width: 100%;
        padding: 0.25rem;
        font-size: 0.5rem;
        margin-top: 30%;
        border-radius: 0.25rem;
    }
}

/* Desktops and Large Laptops */
@media screen and (min-width: 990px) and (max-width: 1296px)    {
    .rememberMeText    {
        font-size: 0.75rem;
    }

    .rememberMeInput    {
        margin-right: 2%;
        margin-left: 5%;
    }
    .forgetPassword    {
        font-size: 0.75rem;
        margin-left: 17%;
    }
    .adminLogin    {
        width: 30%;
    }
    .loginButton    {
        width: 100%;
        padding: 0.25rem;
        font-size: 0.75rem;
        margin-top: 30%;
        border-radius: 0.25rem;
    }
}

/* Large Desktops and High-resolution Displays */
@media screen and (min-width: 1296px)    {
    .rememberMeInput    {
        margin-right: 2%;
        margin-left: 5%;
    }

    .forgetPassword    {
        margin-left: 30%;
    }
}