/* abt-body.css */
*{
  background-color: transparent;
}

.about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: calc(100vh - 100px);
    margin-top: 20px;
    margin-bottom: 20px;
    /* background-image: url(../../../assets/img/back.jpg); */
    padding: 50px;
  }

  .bottom-right {
    position: absolute;
    bottom: 50%;
    right: 16px;
    background-color: transparent;
    font-size: 3rem;
    line-height: normal;
    color: #761034;
    font-weight: bolder ;
    font-style: italic;  
  }

  .card {
    width: calc(30%);
    height: 0;
    padding-bottom: calc(30%);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  .card-image {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s;
  }
  .card:hover .card-image {
    transform: scale(1.1);
  }
  .card-content {
    padding: 15%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .card-title {
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
    font-size: 1.8rem;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .card-body {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.2rem;
    line-height: normal;
    color: #333;
    text-align: center;
  }
  /* Styles for the heading and image */
  .about-heading {
    text-align: center;
    margin-bottom: 20px;

  }
  .about-heading h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    padding: 3.5rem;
  }
  .about-heading img {
    width: 100%;
    height: auto;
    height: 85vh;
    padding: 0px 5rem 0px 5rem;

    /* margin-top: 10px; */
    /* opacity: 0.5; */
  }
  .experiential-container{
    display: flex;
  }
  .experiential-container-pic{
    max-width: 100%;
    height: auto;
    width: 50%;
    padding: 5%;
  }
  .experiential-container-pic img{
    height: auto;
    width: 100%;
    height: auto;
    border-radius: 10%;

  }
  .experiential-container-text{
    padding: 5rem;
    margin-top: 10%;
    width: 50%;
    font-size: 1.4rem;
    line-height: normal;


  }
  /* Media query for responsiveness */
  @media screen and (max-width: 740px) {
    .card {
      width: 100%;
      padding-bottom: 100%;
    }
    .about-heading h1{
        font-size: 1rem;
    }
    .card-title {
      font-size: 1rem;
    }
    .card-body {
      font-size: 0.7rem;
    }
    .bottom-right {
      position: absolute;
      bottom: 20%;
      right: 16px;

    }
    .experiential-container{
     flex-direction: column;
     }

    .experiential-container-pic{
      max-width: 100%;
      height: auto;
;
    }
    .experiential-container-pic img{
      height: auto;
      width: 100%;
      border-radius: 10%;
      justify-content: center;
      margin-left: 50%;    }

    .experiential-container-text{
      padding: 0rem;
      width: 100%;
      height: auto;

  
    }
    .about-heading img{
      width: 100%;
      height: auto;
      height: 60vh;
      padding: 0%;
  
  }

  }


  @media screen and (max-width: 1296px) {
    .experiential-container-pic{
      max-width: 100%;
      height: auto;
      padding: 5%;
    }

    .experiential-container-text{
      padding: 4rem;
      font-size: clamp(1rem, 1.5vw, 1.2rem);
      line-height: normal;
      margin-top: 6%;
  
    }
    .card-content {
      padding: 10%;
      margin-top: 0rem;

    }


  }