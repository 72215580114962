
.news-banner img{
    width: 100%;
    height: auto;
    height: 85vh;
    padding: 0px 5rem 0px 5rem;

}

.news-centered {
    position: absolute;
    top: 20%;
    left: 40%;
  }
  
  .news-container{
   display: flex; 
   margin: 0% 10% 0% 10%;
   margin-top: 5%;

}
.news-container-div1{
    width: 50%;
}

.news-container-div1 img{
height: auto;
width: 100%;
border-radius: 5%;
}

.news-container-div2{
    margin-top: 10%;
    line-height: normal;
    width:50%;
    margin-left: 5%;
}

.news-container-div3{
    margin-top: 10%;
    line-height: normal;
    width:50%;
    margin-right: 5%;
}

.news-container-div2 p{
    font-size: 1.1rem;
}

@media screen and (max-width: 672px) {

    .news-container{

        flex-direction: column;
        width: 100%;
        height: auto;
        margin: 0%;
        /* margin: 10% 10% 10% 10%; */
    }
    
.news-banner img{
    width: 100%;
    height: auto;
    height: 60vh;
    padding: 0%;

}

.news-container-div1{
    width: 100%;
    height: auto;
    margin: 0%;
    padding: 0%;
}
.news-container-div2{
    width: 100%;
    height: auto;
    margin: 0%;
    padding: 0%;
    margin-left: 0%;
}
.news-container-div3{
    width: 100%;
    height: auto;
}



}