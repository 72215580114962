.bottom    {
    background-color: white;
    height: auto;
    display: grid;
}

.bottomLogo    {
    position: relative;
    left: 5%;
    width: 60%;
    margin-bottom: 5px;
}

.bottomLogo img    {
    width: 20%;
    height: auto;
    margin-top: 6%;
    margin-bottom: 3%;
}

.footerTitles    {
    margin-left: 6%;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    gap: 3%;
}

.footerTitle    {
    color: #7C1034 ;
    flex: 1;
    font-size: 1.4rem;
    font-weight: 600;
}

.footerBody    {
    margin-left: 6%;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    gap: 3%;
    font-size: 0.8rem;
}

.bottomListItemLink    {
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
}

.bottomListItemLink :hover    {
    color:#7C1034  ;
}

.information    {
    flex: 1;
}

.infoItem    {
    margin-top: 5%;
    font-size: 1.3rem;
    line-height: normal;
}
.infoItems{
    margin-bottom: 2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.contact    {
    flex: 1;
}

.socials    {
    flex: 1;
}

.infoItemsIcons    {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    gap: 10%;
    font-size: 1.5rem;
}



.facebookIcon :hover    {
    color:#7C1034  ;
}



.instagramIcon :hover    {
    color:#7C1034  ;
}



.linkedinIcon :hover    {
    color:#7C1034  ;
}



.youtubeIcon :hover    {
    color:#7C1034  ;
}

.infoItemIcon    {
    display: flex;
}

.line    {
    margin-top: 2%;
    width: 95%;
    margin-left: 10%;
}

.copyright    {
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: 1rem;
}

/* Mobile Phones */
@media screen and (max-width: 672px)    {
    .bottomLogo img    {
        margin-top: 10%;
        height: auto;
        width: 80%;
    }

    .tedLicense    {
        font-size: 0.5rem;
    }
    .footerTitle    {
        font-size: 0.75rem;
    }
    .footerBody    {
        font-size: 0.75rem;
    }

    .infoItem    {
        font-size: 0.5rem;
    }

    .infoItemsIcons    {
        font-size: 0.75rem;
    }

    .copyright    {
        font-size: 0.5rem;
    }
}

/* Tablets and Small Laptops */
@media screen and (min-width: 672px) and (max-width: 990px)    {
    .bottomLogo img    {
        margin-top: 10%;
        width: 50%;
    }

    .tedLicense    {
        font-size: 0.75rem;
    }
    .footerTitle    {
        font-size: 1rem;
    }
    .footerBody    {
        font-size: 0.75rem;
    }

    .infoItem    {
        font-size: 0.75rem;
    }

    .infoItemsIcons    {
        font-size: 1rem;
    }

    .copyright    {
        font-size: 0.75rem;
    }
}

/* Desktops and Large Laptops */
@media screen and (min-width: 990px) and (max-width: 1296px)    {

}

/* Large Desktops and High-resolution Displays */
@media screen and (min-width: 1296px)    {

}