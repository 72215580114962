.partner   {
    margin-top: 10%;
    background-color: white;
}

.talkTitle    {
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 3%;
}

.talks    {
    color: #7C1034  ;
    font-weight: 800;
    font-size: 1.75rem;
}

.videoContainer    {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.videoItem    {
    margin-left: 3%;
    margin-right: 3%;
}

.videoItem iframe    {
    width: 100%;
    height: 90%;
}

.videoTitle    {
    color: rgb(228, 220, 220);
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    padding-top: 5%;
}

.speakerName    {
    color: rgb(228, 220, 220);
    font-size: 1rem;
    font-weight: 100;
    padding-top: 5%;
}

.viewMoreButton    {
    margin-top: 5%;
    color: rgb(228, 220, 220);
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewMoreButton :hover    {
    color: #7C1034  ;
}

.viewMoreTalks    {
    color: white;
    padding: 0.75rem;
    width: 100%;
    border: 0.1rem solid #7C1034;
    border-radius: 0.5rem;
    background-color: rgb(72, 44, 44);
    font-size: 1rem;
    margin-top: 3%;
}



.zq-partners {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 2rem;
    align-items: center;
    padding: 2rem 0;
    width: 70%;
    margin: 2rem auto;
}

/* Partner Logos */
.zq-partners img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Mobile Phones */
@media screen and (max-width: 672px)    {
    .talkTitle    {
        font-size: 1.5rem;
    }

    .talks    {
        font-size: 1.5rem;
    }

    .videoItem    {
        margin-bottom: 15%;
    }

    .videoTitle    {
        font-size: 0.8rem;
    }

    .speakerName    {
        font-size: 0.8rem;
    }

    .viewMoreButton    {
        margin-top: 0;
    }

    .viewMoreTalks    {
        font-size: 0.75rem;
        padding: 0.75rem;
        border: 0.01rem solid rgb(228, 220, 220);
        width: 100%;
    }
}





/* Tablets and Small Laptops */
@media screen and (min-width: 672px) and (max-width: 990px)    {
    .talkTitle    {
        font-size: 1.5rem;
    }

    .talks    {
        font-size: 1.5rem;
    }

    .videoItem    {
        margin-bottom: 10%;
    }

    .videoTitle    {
        font-size: 0.8rem;
    }

    .speakerName    {
        font-size: 0.8rem;
    }

    .viewMoreButton    {
        margin-top: 0;
    }

    .viewMoreTalks     {
        width: 40%;
        width: 100%;

    }
}

/* Desktops and Large Laptops */
@media screen and (min-width: 990px) and (max-width: 1296px)    {
    .talkTitle    {
        font-size: 1.5rem;
    }

    .talks    {
        font-size: 1.5rem;
    }

    .videoItem    {
        margin-bottom: 7%;
    }

    .videoTitle    {
        font-size: 0.8rem;
    }

    .speakerName    {
        font-size: 0.8rem;
    }

    .viewMoreButton    {
        margin-top: 0;
    }

    .viewMoreTalks    {
        padding: 0.75rem;
        width: 100%;
    }
}

/* Large Desktops and High-resolution Displays */
@media screen and (min-width: 1296px)    {

}