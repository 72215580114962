.contact-body{
/* background-color: rgb(253, 243, 243);    */
 width: 100%;
    height: auto;
}

.contact-title{
    text-align: center;
    color: #761034;
    margin-top: 10%;
    font-weight: bold;
}
.contact-subhead{
    padding: 3% 5% 5% 5%;
    line-height: normal;
    text-align: center;
}


.contact-cards{
    display: flex;
    width: 100%;
    padding: 5rem;
  }

  .contact-containers {
    justify-content: space-around;
    padding: 2rem;
    margin-left: 1.5rem;
    width: 400px;
  
  }

  .contact-containers h3{
    font-weight: bold;
  }

  .contact-container{
    display: flex;
  }
.contact-cards{
    width: 100%;
    padding: 2rem;
  }

  .form-container input[id="message"] {
    width: 100%;
    padding: 5rem;
    margin-bottom: 1rem;
    background-color: white;
  }
 .form-container img{
  width: 100%;
  height: auto;
 }
 .form-group{
  margin-top: 4%;
 }
 .form-container{
  display: flex;
 }
  @media screen and (max-width: 740px)    {


    .contact-cards{
        width: 100%;
        height: auto;
        flex-direction: column;

    }
    .contact-containers{
        width: 230px;
        margin-left: 0rem;
        margin-top: 1rem;
        /* padding: 1rem; */
    }







  }
