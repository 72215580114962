.aboutus-content{
    padding: 5% 25% 5% 25%;
    color: rgb(104, 101, 101);
}

.finance-cards{
    display: flex;
    width: 100%;
    padding: 5rem;
  }

  .finance-containers {
    justify-content: space-around;
    padding: 2rem;
    margin-left: 1.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    width: 400px;
    background-color: #f6f0f2;
  
  }

  .finance-containers h3{
    font-weight: bold;
  }

  .finance-container{
    display: flex;
  }

  .public-cards{
    width: 100%;
    padding: 5rem;
  }

  .public-containers {
    padding: 2px 16px;
    justify-content: space-around;
    padding: 4rem;
    margin-left: 12.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    background-color: white;
    width: 70%;
}

  @media screen and (max-width: 740px)    {


    .finance-cards{
        width: 100%;
        height: auto;
        flex-direction: column;

    }
    .finance-containers{
        width: 230px;
        margin-left: 0rem;
        margin-top: 1rem;
        /* padding: 1rem; */
    }

    .public-containers {
        padding: 1rem;
        margin-left: 0rem;
        width: 100%;
    }





  }