

.carousel-container {
    max-width: 800px; /* Adjust the maximum width as needed */
    margin: 0 auto;
    padding: 0px 20px 20px 20px;
  }
  .trial{
    padding: 0rem 5rem 0rem 5rem;
  }
  .carousel-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #ddd; /* Border for the slide */
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for the slide */
    margin: 10px;
  }
  .carousel-slide img {
    max-width: 100%;
    height: auto;
    max-height: 120px;
  }

  .first{
    height: auto;
    width: 100%;
  }
  .carousel .thumbs-wrapper {
    display: none;
  }
  .carousel.carousel-slider .control-dots {
   color: black;  }

  .left-aligned, .h1, .p{
  position: absolute;
  top: 30%;
  left: 5%;
  text-align: left;
  color: rgb(48, 45, 45);
  font-size: 1.5rem;
  line-height: normal;

  }
  .immigration-text{
    padding: 0% 5% 5% 5%;
    font-size: 1.5rem;
    line-height: normal;
    font-weight: 100;
    color: grey;
  }
  .immigration-text, .h4{
    margin-top: 5rem;
  }

  .immigration-cards{
    display: flex;
    padding: 5%;
 }

  .immigration-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 40%;
    margin-left: 2rem;
    line-height: normal;
 }
  
  .immigration-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .immigration-container {
    padding: 15px 16px;
    font-size: 1rem;
  }


  
  .ed-articles-card-sect {
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
    align-items: center; */
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    width: 80%;
    margin: 2rem auto;
    /* Additional Styling */
    background-color: #ffffff;
    border-radius: 10px;
}

/* Article Card Section Title */
.ed-articles-card-sect h3{
    margin-bottom: 1rem;
}

/* Article Card */
.ed-articles-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    /* padding: 2rem; */
    border-radius: 1rem;
    margin: 0.5rem;
    gap: 1.5rem;
    height: 405px;
    cursor: pointer;
}
.ed-articles-card p{
    font-size: medium;
}
.ed-articles-card-pic{
    padding-top: 0rem;
    object-fit: cover;
    margin-top: 3rem;
}
.ed-articles-card img{
    height: auto;
    height: 15rem;

}

  .zq-articles-card-sect {
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
    align-items: center; */
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    width: 80%;
    margin: 2rem auto;
    /* Additional Styling */
    background-color: #ffffff;
    border-radius: 10px;
}

/* Article Card Section Title */
.zq-articles-card-sect h3{
    margin-bottom: 1rem;
}

/* Article Card */
.zq-articles-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    /* padding: 2rem; */
    border-radius: 1rem;
    margin: 0.5rem;
    gap: 1.5rem;
    height: 405px;
    cursor: pointer;
}
.zq-articles-card p{
    font-size: medium;
}
.zq-articles-card-pic{
    width: 10rem;
    height: 10rem;
    padding-top: 0rem;
    border-radius: 30%;
    object-fit: cover;
    margin-top: 3rem;
}
.zq-articles-card img{
    height: auto;
    height: 10rem;

}

/* Second Article Carousel Card */
.react-multi-carousel-item:nth-child(2n) .zq-articles-card {
    background-color: var(--color-secondary);
    color: var(--light-text);
}

/* Article Carousel Card Hover */
.react-multi-carousel-item:nth-child(2n) .zq-articles-card:hover{
    color: var(--dark-text);
    /* transition */
    transition: all ease-in-out .5s ;
    background-color: #370516;
    color: whitesmoke;
    height: auto;
    border: 1px solid var(--color-secondary);
}
.react-multi-carousel-item:nth-child(2n+1) .zq-articles-card:hover{
    background-color: #370516;
    color: whitesmoke;
    /* transition */
    transition: all ease-in-out .5s ;
    height: auto;
}


  @media screen and (max-width: 672px) {
    
    .trial{
        padding: 0rem;
    }


    .left-aligned, .h1, .p{
        top: 15%;
        left: 5%;
        font-size: 1rem;

        }
    .immigration-cards{
            flex-direction: column;
        }
    .immigration-card {
            width: 100%;
            margin-left: 0rem;
            margin-top: 2rem;
         }




  }