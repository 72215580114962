:root {
  --header-height: 3.5rem;
  --rgb(228, 220, 220)-clr: hsl(0, 0%, 100%);
  --rgb(228, 220, 220)-alt: hsl(240, 11%, 96%);
  --grey-clr: hsl(0, 0%, 54%);
  --grey-clr-alt: hsl(0, 0%, 80%);
  --grey-2: hsl(0, 0%, 53%);
  --black-clr: hsl(0, 0%, 0%);
  --black-clr-alt: #111;
  --archives-clr: hsl(0, 0%, 13%);
  --almond-clr: hsl(33, 41%, 86%);
  --border-clr: hsl(45, 12%, 87%);
  --border-clr-alt: hsla(240, 11%, 96%, 0.541);
  /* --body-font: 'Consolas', 'Courier New', monospace; */

  /* --body-font: 'Raleway', sans-serif; */
  /* --lexend-ff: 'Lexend', sans-serif; */
  --grotesk-ff: 'Space Grotesk', sans-serif;
  --biggest-fs: 2.25rem;
  --bigger-fs: 1.75rem;
  --h1-fs: 1.5rem;
  --h2-fs: 1.25rem;
  --h3-fs: 1rem;
  --normal-fs: .938rem;
  --small-fs: .813rem;
  --smaller-fs: .75rem;
  --regular-fw: 400;
  --medium-fw: 500;
  --semi-bold-fw: 600;
  --bold-fw: 500;
  --z-tooltip: 10;
  --z-fixed: 100;
}

  @media screen and (min-width: 968px) {
    :root {
      --biggest-fs: 4rem;
      --bigger-fs: 3.25rem;
      --h1-fs: 2.25rem;
      --h2-fs: 1.5rem;
      --h3-fs: 1.25rem;
      --normal-fs: 1rem;
      --small-fs: .875rem;
      --smaller-fs: .813rem;
    }
  }
  
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: var(--body-font);
    font-size: var(--normal-fs);
    background-color: white;
    color: var(--rgb(228, 220, 220)-alt);
    min-height: 100%;
    line-height: 20px;
  }
  
  h1, h2, h3 {
    font-weight: var(--bold-fw);
  }
  
  ul {
    list-style: none;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  button,
  input {
    font-family: var(--body-font);
    outline: none;
    border: none;
  }
  
  button {
    cursor: pointer;
  }
  
  .container {
    max-width: 1200px;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  
  .section {
    padding: 4.5rem 0 2rem;
  }
  
  .main {
    overflow: hidden;
  }
  
  .grid {
    display: grid;
  }
  
  .button,
  .home-button {
    background-color: var(--rgb(228, 220, 220)-alt);
    color: var(--black-clr-alt);
    padding: .75rem 1.75rem;
    border: 1px solid var(--border-clr);
    border-radius: 3rem;
    text-transform: uppercase;
    font-weight: var(--bold-fw);
    transition: 0.3s ease;
  }
  
  .button:hover,
  .home-button:hover {
    background-color: transparent;
    color: var(--rgb(228, 220, 220)-alt);
  }
  
  .line {
    margin: 0 1rem;
    height: 1px;
    background-color: var(--border-clr-alt);
  }
  
  .line_2 {
    margin: 2rem 1rem;
    height: 1px;
    background-color: var(--border-clr-alt);
  }
  
  ::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--grey-2);
  }
  
  @media screen and (max-width: 320px) {
    .container {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .section {
      padding: 3.5rem 0 1rem;
    }
  }
  
  @media screen and (min-width: 768px) {
    .section {
      padding: 6rem 0 2rem;
    }
  }
  
  @media screen and (min-width: 1024px),
  screen and (min-width: 1200px) {
    .container {
      margin-left: auto;
      margin-right: auto;
    }
  }
  