.accordionItem    {
  max-width: auto;
  margin: 0 10%;
  color:#7C1034  ;
}
.accordionTitle    {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 800;
  /* background-color: black; */
}
.accordionTitle,
.accordionContent    {
  padding: 1rem;
}


/* Mobile Phones */
@media screen and (max-width: 672px)    {
  .accordionTitle    {
    font-size: 0.8rem;
  }

  .accordionContent     {
    font-size: 0.8rem;
  }
}