

/* .visa-application{
background-color: #eee1db;
background-image: url(../../assets/img/visa-background.jpg);
background-size: cover;
height: auto;
width: 100%; 

} */
.visa-page{
    /* background-image: url(../../assets/img/background-photo.jpg); */
    background-size: cover;
    background-position: center;
    width: 100%;
   flex-wrap: wrap;
}


.visa-banner{
    display: flex;
    margin-top: 5%;
    /* background-image: url(../../assets/img/visa-background.jpg);
    background-size: cover;
    height: auto;
    width: 100%;  */


}

.visa-banner-pic{
    height: auto;
    width: 100%;
    height: 30%;
    width: 30%;
    margin-right: 10%;
}
.visa-banner-pic img{
    height: auto;
    width: 100%;
}

.visa-banner-text{
    height: 50%;
    width: 50%;
    text-align: center;
    margin-top: 10%;
    font-size: 2rem;
    line-height: normal;
    font-style: oblique;
}

/* .visa-application{
    background-color: #777275;


} */
.visa-title{
    margin-left: 15%;
    padding: 1.5rem;
    font-size: 1.2rem;
    line-height: normal;
    margin-top: 10%;
}
.visa-application-static{
    display: flex;
    height: 200px;
    width: 50%;
    background-color: aliceblue;
    font-weight: lighter;
    padding: 2rem;
    line-height: normal;    
    color: #761034;
    margin-left: 15%;
    margin-top: 5%;
    border-radius: 2%;

}

.visa-application-static p{

    margin-top: 2%;
    font-size: 1.2rem;

}

.visa-application-flex p{

    margin-top: 2%;
    font-size: 1.2rem;

}


.visa-application-flex{
    display: flex;
    height: 200px;
    width: 50%;
    background-color: aliceblue;
    font-weight: lighter;
    padding: 2rem;
    line-height: normal;
    color: #761034;
    margin-left: 30%;
    margin-top: 5%;
}

.visa-application-icon{
    width: 30%;

}
.visa-application-text{
    width: 70%;

}
.visa-application-icons{
    width: 100%;
    height: auto;
    width: 90px;

}

hr.new3 {
    border-top: 1px dotted #761034;
    padding: 2%;
    margin-top: 3%;

  }

  .visa-container {
    background-color: #4f0c23;
    height: 30rem;
    margin: 5rem;  
    transition: box-shadow 1.2s; /* Only transition box-shadow */
    border-bottom: 2px solid transparent; /* Set initial border color to transparent */
}

.visa-container:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: #290e18;
    border-bottom-color: #808080; /* Change the color on hover */
}


.visa-container-content{
    color: whitesmoke;
    text-align: center;
    padding: 5% 5% 0% 5%;

}



  @media screen and (max-width: 740px) {

    .visa-application-static{
        flex-direction: column;
        height: 100%;
        width: 80%;
        line-height: normal;    
        margin-top: 5%;
        margin-left: 5%;
    
    }
    
    .visa-application-flex{
        flex-direction: column;
        height: 100%;
        width: 80%;
        line-height: normal;    
        margin-top: 5%;
        margin-left: 5%;
    
    }

    
.visa-banner-pic{
    height: auto;
    width: 100%;
    height: 80%;
    width: 80%;
    margin-top: 10%;
    margin-right: 10%;
}
.visa-banner-pic img{
    height: auto;
    width: 100%;
}

.visa-banner-text{
    height: 50%;
    width: 50%;
    font-size: 1.5rem;
    margin-left: 5%;

}

.visa-page{
    background-image: none;


  }
  .body{
    background-color: none;
  }
  .visa-container {
    height: 20rem;
    margin: 1rem;  

}
.finance-card{
    display: none;
}

}

@media screen and (max-width: 1280px) {
    /* .visa-page{

        height: 90vh; 
    
    } */

}