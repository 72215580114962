/* Constant for All Resolutions */
.vidtitle {
    color: #7C1034;
    font-size: 25px;
    font-weight: 600;
}

.vidtext {
    margin-top: 2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    }

.videotest iframe{
    height: auto;
    width: 100%;
    height: 315px;
    width: 560px;
    border-radius: 5%;

}


/* Mobile Screens */
@media only screen and (max-width: 672px) {
    .videotext,
    .videotext2 {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    }

    .videoplayer {
        margin-left: 0;
        margin-right: 0;
        width: 100%; /* Make the video player full width */
        max-width: 100%; /* Ensure the video does not exceed screen width */
    }

    .text,
    .text2 {
        margin-left: 0;
        margin-right: 0;
        margin-top: 2%;
        width: 100%; /* Make the text content full width */
    }

    .videobody {
        margin-top: 5%;
        margin-left: 6%;
        margin-right: 6%; /* Increase margins for better spacing */
    }

    .vidtitle,
    .vidtext {
        text-align: center; /* Center align the text on smaller screens */
    }
    .videotest iframe{
        height: auto;
        width: 100%;
        margin-top: 5%;

    
    }
    .videotest{
        margin-left: 0%;
    }
}

/* Tablets */
@media screen and (min-width: 672px) and (max-width: 990px) {
    .videotext,
    .videotext2 {
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem; /* Reduce margin for smaller screens */
    }

    .videoplayer {
        margin-left: 0;
        margin-right: 0;
        width: 100%; /* Make the video player full width */
        max-width: 100%; /* Ensure the video does not exceed screen width */
    }

    .text,
    .text2 {
        margin-left: 0;
        margin-right: 0;
        margin-top: 2%;
        width: 100%; /* Make the text content full width */
    }

    .videobody {
        margin-top: 5%;
        margin-left: 6%;
        margin-right: 6%; /* Increase margins for better spacing */
    }
}

/* Desktops and Large Laptops */
@media screen and (min-width: 990px) and (max-width: 1296px) {
    .videobody {
        margin-top: 5%;
    }

    .videotext,
    .videotext2 {
        display: flex;
        flex-direction: row;
        margin-bottom: 3rem; /* Reduce margin for smaller screens */
        margin-left: 6%;
        margin-right: 6%;
    }

    .videoplayer {
        margin-left: 0;
        margin-right: 0;
        width: 50%; /* Adjust video player width for better layout */
    }

    .text,
    .text2 {
        margin-left: 2rem; /* Adjust margin for better spacing */
        margin-right: 2rem; /* Adjust margin for better spacing */
        margin-top: 2rem;
        width: 50%; /* Adjust text content width for better layout */
    }
}

/* Large Desktops and High-resolution Displays */
@media screen and (min-width: 1296px) {
    .videobody {
        margin-top: 5%;
    }

    .videotext,
    .videotext2 {
        display: flex;
        flex-direction: row;
        margin-bottom: 3rem; /* Reduce margin for smaller screens */
        margin-left: 6%;
        margin-right: 6%;
    }

    .videoplayer {
        margin-left: 0;
        margin-right: 0;
        width: 50%; /* Adjust video player width for better layout */
    }

    .text,
    .text2 {
        margin-left: 2rem; /* Adjust margin for better spacing */
        margin-right: 2rem; /* Adjust margin for better spacing */
        margin-top: 2rem;
        width: 50%; /* Adjust text content width for better layout */
    }
}
