.intro    {
    display: flex;
}

.introLeft    {
    /* flex: 0.1; */
    margin-left: 6%;
    padding: 3rem;
}
.introRight{
    padding: 0px;
    margin: 0px;
    margin-right: 0%;
}

.date span    {
    color: #7C1034 ;
    font-size: 1.5rem;
    font-weight: 600;
}


.introTitle     {
    font-size: 3rem;
    font-weight: 800;
    padding-top: 2%;
    line-height: normal;
}

.introTextContent    {
    font-size: 1.5rem;
    padding-top: 5%;
    padding-bottom: 8%;
    font-weight: 100;
    line-height: normal;
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */

}

.leftBtn    {
    color: rgb(228, 220, 220);
    padding: 0.5rem;
    width: 50%;
    border: 0.1rem solid rgb(228, 220, 220);
    border-radius: 0.5rem;
    background-color: rgb(72, 44, 44);
    font-size: 1rem;
}

.leftBtn:hover    {
    background-color: black;
    border: 0.1rem solid #7C1034  ;
    color: #7C1034  ;
}

.rightBtn    {
    flex: 1;
}

.rightBtn    {
    color: rgb(228, 220, 220);
    padding: 0.5rem;
    width: 50%;
    border: 0.1rem solid rgb(228, 220, 220);
    border-radius: 0.5rem;
    margin-left: 2%;
    background-color: rgb(72, 44, 44);
    font-size: 1rem;
}

.rightBtn:hover    {
    background-color: rgb(248, 242, 242);
    border: 0.1rem solid#7C1034  ;
    color:#7C1034  ;
}

.imgRight .container    {
    position: relative;
}

.imgRight   {
    width: 100%;
    height: 100%;
    height: 350px;
    border-radius: 20px;
    object-fit: cover;
    border: 1px solid maroon;

}
.imgRight img{
    height: auto;
    width: 100%;
}

/* Mobile Phones */
@media screen and (max-width: 672px)    {
    .intro    {
        margin-top: 10%;
    }

    .date Span    {
        font-size: 0.8rem;
    }

    .introTitle     {
        font-size: 1.5rem;
    }
    .introTextContent    {
        font-size: 1.3rem;
    }
    .leftBtn    {
        width: 30%;
        font-size: 0.75rem;
        padding: 0.65rem;
    }
    .rightBtn    {
        margin-left: 3%;
        width: 50%;
        font-size: 0.75rem;
        padding: 1rem;
    }

    .introLeft    {
        margin-right: 6%;
        padding: 0rem;
    }

    .introRight    {
        display: none;
    }
}

/* Tablets and Small Laptops */
@media screen and (min-width: 672px) and (max-width: 990px)    {
    .intro    {
        margin-top: 8%;
    }

    .introTitle     {
        font-size: 1.5rem;
    }

    .introTextContent    {
        font-size: 1.3rem;
    }

    .leftBtn    {
        width: 40%;
        font-size: 1rem;
    }
    .rightBtn    {
        margin-left: 6%;
        width: 40%;
        font-size: 1rem;
    }

    .introRight    {
        width: 40%;
        margin-right: 0%;
    }

    .imgRight   {
        width: 75%;
        left: 65%;
        margin-top: -5%;
    }
    .introLeft{
        padding: 0rem;
    }
}

/* Desktops and Large Laptops */
@media screen and (min-width: 990px) and (max-width: 1296px)    {
    .intro    {
        margin-top: 10%;
    }

    .date Span    {
        font-size: 1.3rem;
    }

    .introTitle     {
        font-size: 1.5rem;
    }
    .introTextContent    {
        font-size: 1.3rem;
    }

    .leftBtn    {
        width: 30%;
        font-size: 1rem;
    }
    .rightBtn    {
        margin-left: 6%;
        width: 50%;
        font-size: 1rem;
        padding: 1rem;
    }

    .introRight    {
        width: 60%;
        margin-right: 2%;
    }

    /* .imgRight   {
        width: 75%;
        left: 65%;
        margin-top: -8%;
    } */
}

/* Large Desktops and High-resolution Displays */
@media screen and (min-width: 1296px)    {
    .intro    {
        margin-top: 5%;
    }

    .date Span    {
        font-size: 1rem;
    }

    .introTitle     {
        font-size: 1.5rem;
    }
    .introTextContent    {
        font-size: 1.3rem;
    }

    .leftBtn    {
        width: 20%;
        font-size: 1rem;
    }
    .rightBtn    {
        margin-left: 3%;
        width: 40%;
        padding: 1rem;
        font-size: 1rem;
    }

    .introRight    {
        width: 100%;
        margin-right: 0%;
        margin-top: 5rem;
    }

    .imgRight   {
        width: 75%;
        margin-top: -10%;
        height: 100%;
        height: 400px;
    }
}