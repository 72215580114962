.career-banner::before {
    content: "";
    position: absolute;
    /* top: 0;
    left: 0; */
    width: 100%; /* Adjusted width to 50% */
    height: 80vh;
    background-color: rgba(18, 14, 14, 0.7);
    z-index: 1;
}

.career-banner img{
    width: 100%;
    height: auto;
    height: 80vh;
}

.career-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
  }

  .career-content{
    display: flex;
  }

  .career-content-content{
    padding: 6%;
    width: 40%;
    line-height: normal;
  }

  .career-card{
    height: 250px;
    width: 250px;
    padding: 1rem;
  }
  .career-cards{
    margin-top: 5%;
    width: 30%;
  }
  .career-cards-section{
    margin-top: 5%;
    width: 30%;
  }

  .career-logo{

    /* margin-left: 40%; */
    /* background-color: #F9F9F9; */
    text-align: center;

 }

 .career-logo img{
    height: auto;
    width: 200px;

 }

 .career-text{
    text-align: center;
    line-height: normal;
 }
 .career-choice{
    background-color: #F9F9F9;
 }


 
.tab {
    /* float: left; */
    background-color: #F9F9F9;
    /* width: 30%; */
    display: flex;
    height: 400px;
  }
  .tab-button{
    width: 30%;
    margin-top: 5%;
  }
  .tab-flex{
    width: 70%;
    margin-top: 5%;
  }
  
  .tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 22px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
  }
  
  .tab button:hover {
    color: #f11260;
  }
  
  /* .tab button.active {
    background-color: #ccc;
  } */
  
  .tabcontent {
    /* float: left; */
    padding: 0px 12px;
    width: 70%;
    border-left: none;
    background-color: white;
    height: 80px;
    padding: 3%;
  }

  .tab-content-flex{
    display: flex;
  }
  @media screen and (max-width: 740px)    {

    .career-content{
        flex-direction: column;
      }
      .career-content-content{
        padding: 3%;
        width: 100%;
      }

      .career-cards{
        margin-top: 5%;
        width: 100%;
        /* margin-left: 10%; */
      }
    

  }
  

