.meetTeam {
    font-size: 2rem;
    font-weight: 800;
    text-align: center;
    margin-top: 5%;
    border-radius: 50px;
    line-height: normal;
}
.teamPage{
    margin-top: 2%;
}
.destination-banner img{
    width: 100%;
    height: auto;
    height: 85vh;
    padding: 0px 5rem 0px 5rem;

}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.teamSelectionBar {
    display: flex;
    justify-content: center;
    margin-top: 3%;
}

.tabButton {
    font-size: 1rem;
    /* color: rgb(228, 220, 220); */
    /* background-color: #7C1034; */
    border: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 15%;
}

.tabButton.active {
    border-top: 0.1rem solid #7C1034;
    color: #7C1034;
    /* background-color: #7C1034 */
}

.tabButtonIcon {
    width: 0.8rem;
    height: auto;
    margin-right: 5%;
}

.tabButtonText {
    font-size: 1.5rem;
}

.tabContent {
    display: none;
    margin-bottom: 5%;
}

.tabContent.active {
    display: block;
}

.tabContentContainer {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    /* border-top: 5rem solid #7C1034;
    border-bottom: 3rem solid #7C1034; */
}

.teamMemberContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5%;
    width: 100%;
}

.teamMember {
    flex-basis: calc(45% - 20px);
    /* box-shadow: 10px 10px 5px 12px rgb(222, 226, 227); */
    transition: transform 0.5s, box-shadow 0.5s;
    /* border-radius: 10%; */



}
.teamMember:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);

}

.teamMember img {
    width: 100%;
    height: auto;
    margin-bottom: 10%;
    /* border-radius: 10%; */

}

.teamMemberName {
    color: #6b3648;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
}

.teamMemberPosition {
    font-size: 1.3rem;
    font-weight: 300;
    text-align: center;
    margin-top: 3%;
    margin-bottom: 15%;
}

/* Mobile Phones */
@media screen and (max-width: 672px) {
    .teamSelectionBar {
        flex-direction: column;
    }

    .tabButton {
        width: 100%;
        margin-bottom: 1rem;
    }

    .teamMemberContainer {
        flex-direction: column;
        gap: 2rem;
    }

    .teamMember {
        flex-basis: 100%;
        text-align: center;
    }

    .teamMember img {
        margin-bottom: 5%;
    }

    .teamMemberName,
    .teamMemberPosition {
        font-size: 1rem;
        margin-bottom: 10px;
    }
    .destination-banner img{
        width: 100%;
        height: auto;
        padding: 0%;
        height: 60vh;
    
    }
    

}
