/* abt-body.css */

.speaker {
    margin-top: 10%;
    background-color: white;
    margin-left: 3%;
    margin-right: 3%;
  }
  .speakerTitle {
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 3%;
    padding: 2rem;
  }
  .speakers {
    color: #7C1034;
    font-weight: 800;
    font-size: 1.75rem;
  }
  .speakerContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .speakerItem {
    width: 20%;
    margin: 0 2%;
    text-align: center;
    overflow: hidden;
  }
  .speakerItem img {
    object-fit: cover;
    border-radius: 5%;
    max-width: 100%;
    height: auto;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .speakerName {
    color: #7C1034;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    padding-top: 5%;
    padding-bottom: 3%;
    line-height: normal;
  }
  .topic {
    font-size: 1.1rem;
    font-weight: 300;
    text-align: center;
    line-height: normal;
  }
  /* Responsive CSS */
  @media screen and (max-width: 672px) {
    .speakerTitle {
      font-size: 1.5rem;
      margin-bottom: 5%;
    }
    .speakers {
      font-size: 1.5rem;
    }
    .speakerItem {
      width: 45%; /* Adjust the width for smaller screens */
      margin: 0 2%;
    }
    .speakerName {
      font-size: 1rem;
      padding-top: 5%;
      padding-bottom: 3%;
      line-height: normal;

    }
    .topic {
      font-size: 0.8rem;
      line-height: normal;
    }
  }
  @media screen and (min-width: 672px) and (max-width: 990px) {
    .speakerTitle {
      font-size: 1.5rem;
      margin-bottom: 5%;
    }
    .speakers {
      font-size: 1.5rem;
    }
    .speakerItem {
      width: 30%; /* Adjust the width for smaller screens */
      margin: 0 2%;
    }
    .speakerName {
      font-size: 1rem;
      padding-top: 5%;
      padding-bottom: 3%;
    }
    .topic {
      font-size: 0.8rem;
    }
  }
  @media screen and (min-width: 990px) and (max-width: 1296px) {
    /* You can define styles for this screen size range here */
  }
  @media screen and (min-width: 1296px) {
    /* You can define styles for larger screens here */
  }